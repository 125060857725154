<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="title"
    :visible.sync="dialogFormVisible"
    @close="close"
    width="1340px"
  >
    <el-form ref="form" label-width="100px" :model="form" :rules="rules">
      <el-form-item style="margin-bottom: 20px" label="品牌名称" prop="name">
        <el-input v-model="form.name" size="small" />
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="品牌LOGO">
        <el-button v-if="!form.logo" icon="el-icon-plus" plain style="width: 100px; height: 100px;border-radius: 10px" @click="showSelecter = true;picType = 'logo'"/>
        <div v-else @click="showSelecter = true">
          <el-image :src="form.logo" style="width: 100px; height: 100px;border-radius: 10px" fit="contain"/>
        </div>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="品牌专区大图">
        <el-button v-if="!form.big_img" icon="el-icon-plus" plain style="width: 100px; height: 100px;border-radius: 10px" @click="showSelecter = true;picType = 'big_img'"/>
        <div v-else @click="showSelecter = true">
          <el-image :src="form.big_img" style="width: 100px; height: 100px;border-radius: 10px" fit="contain"/>
        </div>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="品牌故事" prop="details">
        <tinymce v-model="form.details" placeholder="请输入内容" :height="400" :width="1110"/>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="排序" prop="sort">
        <el-input-number v-model="form.sort" step-strictly controls-position="right" :min="1" size="small" />
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="是否显示" prop="is_show">
        <el-switch
            v-model="form.is_show"
            active-text="是"
            :active-value="1"
            inactive-text="否"
            :inactive-value="0"
        />
        <div>当品牌下还没商品的时候,分页类的品牌区将不会显示该品牌</div>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close" size="small">取 消</el-button>
      <el-button type="primary" @click="save" size="small">确 定</el-button>
    </div>
    <fileSelect :visible.sync="showSelecter" title="选择商品图片" state="image" append-to-body @confirm="showSelecterSubmit"></fileSelect>
  </el-dialog>
</template>

<script>
import fileSelect  from "@/views/components/fileSelect";
import {apiAddBrand} from "@/request/api";
  export default {
    name: 'AdminEdit',
    components: {
      fileSelect
    },
    data() {
      return {
        form: {
          id: '',
          name: '',
          logo: '',
          big_img: '',
          details: '',
          sort: 1,
          is_show: '',
        },
        rules: {
          name: [
            { required: true, trigger: 'blur', message: '请输入品牌名称' },
          ],
        },
        title: '',
        dialogFormVisible: false,
        showSelecter: false,
        picType: '',
      }
    },
    methods: {
      showEdit(row) {
        if (!row) {
          this.title = '添加'
        } else {
          this.title = '修改'
          this.form = Object.assign({}, row)
        }
        this.dialogFormVisible = true
      },
      showSelecterSubmit(data) {
        if (this.picType == 'logo') {
          this.form.logo = data[0].origin
        }
        if(this.picType == 'big_img'){
          this.form.big_img = data[0].origin
        }
      },
      save() {
        apiAddBrand(this.form).then(res => {
          if (res.code == 200) {
            this.dialogFormVisible = false
            this.$emit('refresh')
          }
        })
      },
      close() {
        this.dialogFormVisible = false
      },
    },
  }
</script>
