<template>
  <div class="page">
    <div class="pageFucView">
      <div class="pageFucView_left"></div>
      <div class="pageFucView_right">
        <el-form style="display: flex" label-width="80px" label-position="left">
          <el-form-item label="品牌名称:" style="margin-top: 0">
            <el-input v-model="search.name" placeholder="请输入品牌名称"></el-input>
          </el-form-item>

          <el-button type="primary" icon="el-icon-search" @click="searchHandle">搜索</el-button>
          <el-button icon="el-icon-refresh-right" @click="resetHandle">重置</el-button>
        </el-form>
      </div>
    </div>

    <div style="margin-bottom: 10px">
      <el-button class="button" icon="el-icon-plus" type="primary" @click="handleEdit" size="small">添加</el-button>
      <el-button class="button" @click="reCreateInitialHandle" size="small">重新生成品牌首字母</el-button>
    </div>

    <el-table
        :data="list"
        style="width: 100%"
        border
        >
      <el-table-column
          prop="id"
          label="编号"
          width="80">
      </el-table-column>
      <el-table-column
          label="品牌">
        <template slot-scope="scope">
          <div class="infoView">
            <el-image class="infoPic" :src="scope.row.logo" :preview-src-list="[scope.row.big_img]"></el-image>
            <span class="infoName">{{ scope.row.name }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
          prop="initial"
          label="品牌首字母">
      </el-table-column>
      <el-table-column
          prop="sort"
          label="排序">
      </el-table-column>
      <el-table-column
          align="center"
          label="是否显示"
          show-overflow-tooltip
      >
        <template #default="{ row }">
          <el-tooltip
              :content="row.is_show == 0 ? '点击显示' : '点击不显示'"
              :enterable="false"
              placement="top"
          >
            <el-switch
                v-model="row.is_show"
                :active-value="1"
                :inactive-value="0"
                :value="true"
                @change="changeShowHandle(row.id, row.is_show)"
            />
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column align="center" label="相关" show-overflow-tooltip width="500">
        <template #default="{ row }">
          <el-button type="text" @click="toGoodsList(row.id,row.name)">商品列表</el-button>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="120">
        <template #default="{ row }">
          <el-button type="text" @click="handleEdit(row)">修改</el-button>
          <el-button type="text" @click="handleDelete(row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="pageView">
      <el-pagination layout="prev, pager, next" :page-size="search['per-page']" :current-page.sync="search.page" :total="pageCount" @current-change="pageChangeHandle" />
    </div>
    <Edit ref="Edit" @refresh="resetHandle" />
  </div>
</template>
<script>
  import Edit from './components/brand_Edit'
  import {apiDeleteBrand,apiAddBrand, apiGetBrandList, apiRebuildBrand} from "@/request/api";

  export default {
    filters: {
      formatDate(time) {
        var date = new Date(time * 1000);
        return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
      }
    },
    components: { Edit },
    data() {
      return {
        pageCount: 0,
        list: [],
        search:{}
      }
    },
    created() {
      this.initSearch()
      this.getData()
    },
    methods: {
      initSearch() {
        this.search = {
          name: '',
          page: 1,
          'per-page': 10,
        }
      },
      resetHandle() {
        this.initSearch()
        this.getData()
      },
      searchHandle() {
        this.search.page = 1;
        this.getData()
      },
      pageChangeHandle(){
        this.getData()
        window.screenTop = 0;
      },
      getData() {
        apiGetBrandList(this.search).then(res => {
          if(res.code == 200){
            this.list = res.data.list
            this.pageCount = res.data.count
          }
        })
      },
      handleDelete(id){
        this.$confirm('是否删除该品牌?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          apiDeleteBrand({id:id}).then(res => {
            if(res.code == 200) {
              this.getData()
            }
          })
        })
      },
      reCreateInitialHandle(){
        apiRebuildBrand().then(res => {
          if(res.code == 200) {
            this.initSearch()
            this.getData()
          }
        })
      },
      changeShowHandle(id, is_show) {
        apiAddBrand({id:id, is_show:is_show}).then(res => {
          if(res.code == 200) {
            this.getData()
          }
        })
      },
      handleEdit(row) {
        if (row.id) {
          this.$refs['Edit'].showEdit(row)
        } else {
          this.$refs['Edit'].showEdit()
        }
      },
      toGoodsList(id,name) {
        console.log(id)
        this.$router.push({
          name: 'goodsList',
          params: {
            brand_id: id,
            name:name
          }
        })
      }
    }
  }

</script>
<style scoped>
.page {
  background-color: #FFF;
  padding: 20px;
  margin: 20px;
  margin-bottom: 80px;
}
.pageView {
  display: flex;
  justify-content: flex-end;
}
.pageFucView {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.pageFucView_right {
  display: flex;
}
.el-form-item {
  margin-bottom: 0px !important;
  margin-right: 10px;
}
.infoView {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  align-items: center;
}
.infoView .infoPic {
  width: 45px;height: 45px;border-radius: 10px;margin-right: 10px
}
.infoView .infoName {
  max-height: 50px;
  width: calc(100% - 45px - 10px);
  overflow:hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
